import {
  CURRENT_CART_VERSION,
  defaultCart,
  products,
  programs,
} from "../context/CartContext";
import useLocalStorage from "../hooks/useLocalStorage";

/**
 * @typedef {import('../context/CartContext').ProgramName} ProgramName
 * @typedef {import('../context/CartContext').ProductName} ProductName
 * @typedef {import('../context/CartContext').StripeCoupon} StripeCoupon
 * @typedef {import('../context/CartContext').Cart} Cart
 */

/**
 * Hook to access the current cart and update its items
 * @returns {[Cart, Function]}
 */
export function useCart() {
  /** @type {[Cart, Function]} */
  const [storedCart, setStoredCart] = useLocalStorage("cart", defaultCart);

  if (storedCart.version !== CURRENT_CART_VERSION) {
    // Reset the cart if version has changed
    setStoredCart(defaultCart);
  }

  /**
   * Helper for managing cart state : add and remove products, programs, coupons...
   * @param {'add'|'remove'|'reset'} action - operation to perform
   * @param {'program'|'product'|'coupon'} type - object to perform the operation on
   * @param {ProgramName|ProductName|StripeCoupon} value - specific payload: either a product id, a program id, a Stripe coupon, or undefined (eg to remove something)
   * @param {number} quantity - optional, only relevant for adding products
   * @returns {Void}
   */
  function updateCart(action, type, value, quantity = 1) {
    if (
      (type === "program" && !Object.values(programs).includes(value)) ||
      (type === "product" && !Object.values(products).includes(value))
    ) {
      throw new Error(`invalid ${type} identifier: ${value}`);
    }

    const newCart = { ...storedCart };

    switch (type) {
      case "coupon":
        if (action === "remove") {
          newCart.coupon = null;
        }
        if (action === "add") {
          newCart.coupon = value;
        }
        break;

      case "product":
        if (action === "add") {
          const existingProduct = newCart.contents.items.find(
            (product) => product.id === value
          );

          if (existingProduct) {
            existingProduct.quantity = quantity;
          } else {
            const productToAdd = {
              ...newCart.definition.availableProducts[value],
              quantity,
            };
            newCart.contents.items.push(productToAdd);
          }
        }
        if (action === "remove") {
          newCart.contents.items = newCart.contents.items.filter(
            (product) => product.id !== value
          );
        }
        break;

      case "program":
        if (action === "add") {
          // Clear all items when adding a program
          newCart.contents.items = [];
          newCart.contents.program = value;
          for (const product of newCart.definition.availablePrograms[value]
            .products) {
            const productToAdd = {
              ...newCart.definition.availableProducts[product],
              quantity: 1,
            };
            newCart.contents.items.push(productToAdd);
          }
        }
        break;

      default:
        if (action === "reset") {
          return setStoredCart(defaultCart);
        }
        throw new Error(`Invalid type: ${type}`);
    }

    // COMPUTE DISCOUNT
    if (!newCart.coupon) {
      newCart.discountAmount = null;
      newCart.discountPercent = null;
      for (const item of newCart.contents.items) {
        item.discountedPrice = null;
      }
    } else {
      const coupon = newCart.coupon.coupon;

      if (
        coupon.applies_to &&
        coupon.applies_to.products &&
        coupon.applies_to.products.length > 0
      ) {
        // We know the coupon applies only to a subset of the cart

        // --> remove previously set global discount
        newCart.discountAmount = null;
        newCart.discountPercent = null;

        for (const item of newCart.contents.items) {
          const isCouponValidForCurrentItem =
            coupon.applies_to.products.includes(item.stripe_product_id);

          if (isCouponValidForCurrentItem) {
            const percentOff = coupon.percent_off;
            const amountOff = coupon.amount_off / 100;
            let discountedPrice = item.price * item.quantity; // MULTIPLY BY QUANTITY
            if (percentOff) {
              discountedPrice = (discountedPrice * (100 - percentOff)) / 100;
            }
            if (amountOff) {
              discountedPrice -= amountOff;
            }
            item.discountedPrice = discountedPrice;
          } else {
            item.discountedPrice = null;
          }
        }
      } else {
        const percentOff = coupon.percent_off;
        const amountOff = coupon.amount_off / 100;
        if (percentOff) {
          newCart.discountAmount = null;
          newCart.discountPercent = percentOff;
        }
        if (amountOff) {
          newCart.discountAmount = amountOff;
          newCart.discountPercent = null;
        }
      }
    }

    // COMPUTE TOTALS

    let _subtotal = 0;
    let _discount = 0;
    for (const item of newCart.contents.items) {
      const itemPrice =
        newCart.contents.program === programs.SHOP && item.shopPrice
          ? item.shopPrice
          : item.price;

      _subtotal += itemPrice * item.quantity;

      if (item.discountedPrice !== null) {
        _discount += itemPrice * item.quantity - item.discountedPrice;
      }
    }

    newCart.subtotal = _subtotal;
    if (_discount) {
      newCart.discountAmount = _discount;
      newCart.discountPercent = newCart.coupon.coupon.percent_off;
    }

    if (newCart.discountAmount === null && newCart.discountPercent === null) {
      newCart.total = _subtotal;
    }
    if (newCart.discountAmount) {
      newCart.total = _subtotal - newCart.discountAmount;
    }
    if (newCart.discountPercent) {
      newCart.total = (_subtotal * (100 - newCart.discountPercent)) / 100;
    }

    if (newCart.total < 0) {
      newCart.total = 0;
    }

    const isShippingExemptedFromCoupon =
      newCart.coupon &&
      !newCart.coupon.coupon.applies_to &&
      newCart.coupon.coupon.percent_off === 100;
    const shippingProductIsExempted =
      newCart?.coupon?.coupon.applies_to?.products.includes(
        process.env.GATSBY_STRIPE_PROD_SHIPPING
      );

    newCart.freeShipping =
      newCart.total >= 0 ||
      newCart.total === 0 ||
      isShippingExemptedFromCoupon ||
      shippingProductIsExempted;
    if (!newCart.freeShipping) {
      newCart.total += newCart.definition.shippingFees;
    }

    setStoredCart(newCart);
  }

  return [storedCart, updateCart];
}
